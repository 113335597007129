import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import chevronBlue from "../../images/icons/chevron-right_blue.svg";
import chevronWhite from "../../images/icons/chevron-right_white.svg";
import { withMargin } from "../../theme/spacing";
import Loading from "./Loading";

const Container = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => (props.variant === "primary" ? "22px" : "18px")};
  padding: ${props => (props.variant === "primary" ? "16px 32px" : "16px")};
  background-color: ${props =>
    props.variant === "primary" || props.variant === "secondary"
      ? props.theme.colors.blue
      : props.theme.colors.white};
  font-weight: ${props => (props.variant === "primary" ? 600 : 400)};
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-align: center;
  max-width: fit-content;
  outline: none;
  border: none;
  border-radius: 0;
  transition: background 100ms ease-in-out 0s;
  text-decoration: none;
  cursor: pointer;
  color: ${props =>
    props.variant === "primary" || props.variant === "secondary"
      ? props.theme.colors.white
      : props.theme.colors.blue};

  ${props =>
    props.variant !== "primary" &&
    css`
      ${props => props.variant === "back" && "padding-left: 38px;"}
      ${props =>
        props.variant !== "back" && "padding-right: 38px;"}

      &::after {
        position: absolute;
        top: calc(50% - 11px);
        ${props => (props.variant === "back" ? "left: 16px;" : "right: 16px;")}
        width: 8px;
        height: 22px;
        display: block;
        ${props => props.variant === "back" && "transform: rotate(180deg);"};
        content: url(${props =>
          props.variant === "secondary" ? chevronWhite : chevronBlue});
        transition: transform 100ms ease-in-out 0s;
      }
    `}

  &:hover {
    background-color: ${props =>
      props.variant === "primary" || props.variant === "secondary"
        ? props.theme.colors.blueHover
        : "rgba(250, 250, 250, .8)"};

    &::after {
      transform: ${props =>
        props.variant === "back"
          ? "rotate(180deg) translateX(9px)"
          : "translateX(9px)"};
    }
  }

  ${props => withMargin(props)}
`;

const Title = styled.span`
  display: block;
  transition: all 100ms ease-in-out;
  opacity: ${props => (props.isLoading ? 0 : 1)};
  transform: translateY(${props => (props.isLoading ? "100%" : 0)});
`;

const LoadingContainer = styled.span`
  position: absolute;
  width: 28px;
  height: 28px;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  transform: translateY(${props => (props.isLoading ? 0 : "100%")});
  transition: all 100ms ease-in-out;
`;

const Button = ({
  title,
  onClick,
  submit = false,
  margin,
  variant = "tertiary",
  loading = false,
  disabled = false,
}) => (
  <Container
    onClick={onClick}
    type={submit ? "submit" : "button"}
    variant={variant}
    margin={margin}
    disabled={disabled}
  >
    <Title isLoading={loading}>{title}</Title>
    <LoadingContainer isLoading={loading}>
      <Loading active={loading} />
    </LoadingContainer>
  </Container>
);

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "back"]),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
