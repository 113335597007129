import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

import LoadingIcon from "../../images/icons/loading.inline.svg";
import { withMargin } from "../../theme/spacing";

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.span`
  width: 32px;
  height: 32px;

  svg {
    ${props =>
      props.active &&
      css`
        animation: ${Spin} 1s infinite;
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      `}

    path {
      stroke: ${props =>
        props.variant === "light"
          ? props.theme.colors.white
          : props.theme.colors.primary};
    }
  }

  ${props => withMargin(props)}
`;

const Loading = ({ variant = "light", active = true, margin }) => (
  <Container variant={variant} margin={margin} active={active}>
    <LoadingIcon width="100%" height="100%" />
  </Container>
);

Loading.propTypes = {
  variant: PropTypes.oneOf(["light", "dark"]),
  active: PropTypes.bool,
};

export default Loading;
